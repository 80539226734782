var CompensationForm = function () {
    var _this = this;
    this.form = document.getElementById('compensation-form');
    this.formElements = this.form.querySelectorAll('[name]');
    this.planSelector = null;
    this.error = false;
    this.errors = [];
    this.total = 0;
    this.currentlySubmitting = false;
    this.postUrl = this.form.dataset.url;
    this.step = (document.getElementById('current-step') ? parseInt(document.getElementById('current-step').value) : '');
    this.planData = (document.getElementById('plan-data') ? JSON.parse(document.getElementById('plan-data').value) : '');
    this.monthlyCost = (VuriaUtil.elementExists('#monthly-cost') ? parseFloat(document.getElementById('monthly-cost').value) : 25);
    this.monthlyFee = (VuriaUtil.elementExists('#monthly-fee') ? parseFloat(document.getElementById('monthly-fee').value) : 25);
    this.selectedPlan = (this.planData ? this.planData.selected_plan : '');
    this.initialPaymentDropdown = null;
    this.previousPlan = null;
    this.braintreeForm = null;
    this.submitButton = this.form.querySelector('#submit');
    this.removeFields = ['card_number', 'cvv', 'expiration_date'];
    this.select2Options = {
        minimumResultsForSearch: -1,

    };

    if (this.step === 8 ||  !this.step) {
        return;
    }

    this.exitAndSaveButton = this.form.querySelector('#exit-and-save');

    if (VuriaUtil.elementExists('.match-height')) {
        Array.prototype.slice.call(this.form.querySelectorAll('.match-height')).forEach(function (element) {
            VuriaUtil.matchHeight(element);
        })
    }

    this.addEventListeners();

    if (this.step === 2) {
        new Cleave('#phone-number', {
            phone: true,
            phoneRegionCode: 'us'
        })
    }

    if (this.step === 3) {
        this.icaLink = this.form.querySelector('.ica-link');
        this.prepareStep3();
    }

    if (this.step === 4) {
        this.planPrintouts = document.querySelectorAll('.plan-document');
        this.prepareStep4();
    }

    if (this.step === 5) {
        this.prepareStep5();
    }

    if (this.step === 6) {
        this.prepareStep6();
    }

    this.form.CompensationForm = this;
};

CompensationForm.prototype.addEventListeners = function () {
    var _this = this;

    this.submitButton.addEventListener('click', _this.submit.bind(_this));

    for (var idx in this.formElements) {
        var element = _this.formElements[idx];

        if (element instanceof HTMLElement) {
            if (element.tagName.toLowerCase() === 'select') {
                $(element).select2(_this.select2Options)
            }
        }
    }

    if (this.step === 4 || this.step === 5) {
        this.initialPayment = this.form.querySelector('#selected-payment').value || 200;
        this.initialPaymentDropdown = this.form.querySelector('[name="initial_payment"]');
        this.couponCodeField = this.form.querySelector('[name="coupon_code"]');
        this.couponSubmit = this.form.querySelector('.apply-coupon-btn');

        $(this.initialPaymentDropdown).on('change', function () {
            _this.initialPayment = parseFloat(this.value);
            _this.updateFees();
        });

        this.couponSubmit.addEventListener('click', _this.applyNewCoupon.bind(_this));
    }

    if (this.step === 5) {
        this.braintreeForm = new WestUsaBraintree(_this);
        this.updatePlan();
        this.braintreeForm.showForm();
        this.form.querySelector('#comp-no-cost').style.display = 'none';
    }

    if (this.exitAndSaveButton) {
        this.exitAndSaveButton.addEventListener('click', _this.exitAndSave.bind(_this));
    }
};

CompensationForm.prototype.validate = function () {
    var _this = this;
    this.errors = [];

    for (var idx in this.formElements) {
        var element = _this.formElements[idx];

        if (element instanceof HTMLElement && element.classList.contains('required')) {
            if (
                !element.value
                ||
                (element.type === 'checkbox' && !element.checked)
            ) {
                element.getParentByClass('form-group').classList.add('form-error');
                if (element.getParentByClass('form-error')) {
                    element.getParentByClass('form-error').querySelector('.error-message').textContent = 'This field is required.';
                }
                _this.error = true;
                _this.errors.push(element);
            } else if (element.type === 'radio') {
                var selected = false;
                var radios = _this.form.querySelectorAll('[name="' + element.name + '"]');
                for (var idx in radios) {
                    var radio = radios[idx];
                    if (radio instanceof HTMLElement) {
                        if (radio.checked) {
                            selected = true;
                        }
                    }
                }


                if (!selected) {
                    element.getParentByClass('form-group').classList.add('form-error');
                    if (element.getParentByClass('form-error')) {
                        element.getParentByClass('form-error').querySelector('.error-message').textContent = 'This field is required.';
                    }
                    _this.error = true;
                    _this.errors.push(element);
                } else {
                    element.getParentByClass('form-group').classList.remove('form-error');
                    if (element.getParentByClass('form-error')) {
                        element.getParentByClass('form-error').querySelector('.error-message').textContent = '';
                    }
                }
            } else {
                if (element.name === 'card_number' && element.value.length < 16) {
                    this.error = true;
                    element.parentNode.classList.add('form-error');
                    element.parentNode.querySelector('.error-message').innerHTML = 'Please enter a 16 digit card number.';
                    this.errors.push(element);

                } else {
                    if (element.getParentByClass('form-error')) {
                        element.getParentByClass('form-error').querySelector('.error-message').textContent = '';
                        element.getParentByClass('form-error').classList.remove('form-error');
                    }
                }
            }
        }
    }

    if (this.step === 5 && w9.enabled) {
        var mustSignW9Message = document.querySelector('.must-sign-w9-message');
        if (mustSignW9Message) {
            if (!(w9.hasCompleted || w9.hasSubsequentlyCompleted)) {
                mustSignW9Message.classList.remove('display-none');
                this.errors.push(mustSignW9Message);
            } else {
                mustSignW9Message.classList.add('display-none');
            }
        }
    }

    VuriaUtil.matchHeightUpdate();

    if (!Object.keys(this.errors).length) {
        this.error = false;
    } else {
        this.stopLoading();
        if (this.errors[0]) {
            $('html, body').animate({
                scrollTop: ($(_this.errors[0].parentNode).offset().top - (window.innerHeight / 2))
            }, 400);
            this.errors[0].focus();
        }
    }
};

CompensationForm.prototype.submit = function (nonce) {
    this.startLoading();
    var _this = this;

    if (nonce) {
        if (typeof nonce === 'string') {
            this.form.querySelector('#braintree-nonce').value = nonce;
            this.currentlySubmitting = false;
        } else {
            nonce.preventDefault();

            if (!this.form.querySelector('[name="update"]')) {
                this.validate();
                this.currentlySubmitting = false;

                if (this.total) {
                    if (this.braintreeForm && !this.error && !this.braintreeForm.paid && !this.currentlySubmitting) {
                        this.currentlySubmitting = true;
                        this.braintreeForm.validate(true);
                        return;
                    }
                }
            } else {
                this.currentlySubmitting = false;
            }

            if (this.step === 4 || this.step === 5) {
                if (document.querySelector('input[name="coupon_code"]').value) {
                    this.applyNewCoupon(nonce);
                }
            }

            if (this.step === 2 && Recaptcha && !this.form.querySelector('input[name="g-recaptcha-response"]')) {
                var event = document.createEvent('Event');
                event.initEvent('submit', true, true, {});
                this.form.dispatchEvent(event);
                return false;
            }
        }
    }


    if (!this.error && !this.errors.length  && !this.currentlySubmitting) {
        var formData = this.form.serializeData();

        for (var key in formData) {
            if (_this.removeFields.indexOf(key) > -1) {
                delete formData[key];
            }
        }

        jsonPost(_this.postUrl, formData, _this.handleResponse.bind(_this), true);
    } else {
        this.stopLoading();
    }
};

CompensationForm.prototype.handleResponse = function (responseData) {
    var _this = this;

    function maybeResetCaptcha() {
        if (Recaptcha) {
            grecaptcha.reset();
            var node = _this.form.querySelector('input[name="g-recaptcha-response"]');
            if (node) {
                _this.form.removeChild(node);
            }
        }
    }

    if (responseData.status >= 200 && responseData.status < 400) {
        var response = JSON.parse(responseData.responseText);
        if (!response.success) {
            var element = null;
            var isElementVisible = false;
            for (var key in response.errors) {
                var message = response.errors[key];
                element = _this.form.querySelector('[name="' + key + '"]');
                isElementVisible = element && element.type !== 'hidden';

                if (isElementVisible) {
                    element.parentNode.classList.add('form-error');
                    element.nextElementSibling.innerHTML = message;
                } else {
                    alert(message);
                }
            }
            maybeResetCaptcha();

            if (!!element && isElementVisible) {
                $('html, body').animate({
                    scrollTop: ($(element).offset().top - (window.innerHeight / 2))
                }, 400);
                element.focus();
            }
            this.stopLoading();

            if (response.braintree) {
                alert(response.message);
            }
        } else {
            window.location.href = response.url;
        }
    } else {
        maybeResetCaptcha();
        alert('An error occurred trying to submit your data.')
        this.stopLoading();
    }

    this.stopLoading();
    this.currentlySubmitting = false;
};

CompensationForm.prototype.exitAndSave = function (event) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.value = 1;
    input.name = 'update';

    this.form.appendChild(input);
    this.submit(event);
}

CompensationForm.prototype.startLoading = function () {
    var _this = this;
    this.submitButton.emptyNode();

    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-spinner', 'fa-spin');
    this.submitButton.appendChild(icon);
    this.submitButton.disabled = true;
}

CompensationForm.prototype.stopLoading = function () {
    var _this = this;
    this.submitButton.emptyNode();

    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-angle-right');
    this.submitButton.textContent = 'Next';
    this.submitButton.appendChild(icon);
    this.submitButton.disabled = false;
}

CompensationForm.prototype.applyNewCoupon = function (event) {
    var _this = this;
    event.preventDefault();
    this.startLoadingCoupon();
    jsonPost(_this.couponSubmit.dataset.action, this.form.serializeData(), this.couponResponse.bind(this), false);
}

CompensationForm.prototype.couponResponse = function (responseData) {
    var _this = this;

    if (responseData.status >= 200 && responseData.status < 400) {
        var response = JSON.parse(responseData.responseText);
        if (response.success) {
            _this.planData = response.data;
            if (_this.step === 4) {
                _this.monthlyCost = parseFloat(_this.planSelector.get());
            }
            _this.updatePlan();
        } else {
            alert(response.message);
            if (!response.success) {
                this.error = true;
                document.querySelector('input[name="coupon_code"]').value = '';
            }
        }
    } else {
        alert('An error occurred while talking to the server.');
    }

    this.stopLoadingCoupon();
}

CompensationForm.prototype.startLoadingCoupon = function () {
    var _this = this;
    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-spinner', 'fa-spin');

    this.couponSubmit.innerHTML = '';
    this.couponSubmit.appendChild(icon);
}

CompensationForm.prototype.stopLoadingCoupon = function () {
    var _this = this;

    this.couponSubmit.innerHTML = '';
    this.couponSubmit.textContent = 'Apply';
}

CompensationForm.prototype.prepareStep3 = function () {
    var _this = this;
    var icaForm = document.querySelector('.ica-modal').cloneNode(true).innerHTML;
    var modal;

    new Cleave('#birth-date', {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'd', 'Y']
    });

    new Cleave('#home-phone', {
        phone: true,
        phoneRegionCode: 'us',
    })

    new Cleave('#mobile-phone', {
        phone: true,
        phoneRegionCode: 'us',
    })

    this.icaLink.addEventListener('click', function (event) {
        event.preventDefault();
        modal = bootbox.dialog({
            title: '',
            message: icaForm
        }).off("shown.bs.modal").init(function () {
            $('.modal-dialog').addClass('ica-modal');
        });

        var inputs = Array.prototype.slice.call(document.querySelector('.modal-body').querySelectorAll('input'));
        var submit = document.querySelector('.modal-body').querySelector('input[value="submit"]');

        submit.addEventListener('click', function (event) {
            event.preventDefault();
            var errors = [];

            inputs.forEach(function (element) {
                if (element.type !== 'submit') {
                    if (!element.value && element.classList.contains('required')) {
                        element.parentNode.classList.add('form-error');
                        if (element.parentNode.querySelector('.error-message')) {
                            element.parentNode.querySelector('.error-message').textContent = 'This field is required.';
                        }
                        errors.push(element);
                    } else {
                        element.parentNode.classList.remove('form-error');
                        if (element.parentNode.querySelector('.error-message')) {
                            element.parentNode.querySelector('.error-message').textContent = '';
                        }
                    }
                }
            })

            if (!errors.length) {
                inputs.forEach(function (element) {
                    if (element.type !== 'submit') {
                        var value = element.value;
                        element = _this.form.querySelector('[name="' + element.name + '"]');
                        element.value = value;
                    }
                })
                $('button.bootbox-close-button').trigger('click')
                _this.form.querySelector('[name="contractor_agreement"]').checked = true;
            } else {
                errors[0].focus();
            }

        })
    })

    var boardMemberships = this.form.querySelectorAll('[name="board_membership_form"]');
    Array.prototype.slice.call(boardMemberships).forEach(function (boardMembership) {
        boardMembership.addEventListener('change', function (event) {
            var element = event.target;
            if (element.parentNode.textContent.toLowerCase() === 'yes') {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'none';
            } else if (element.parentNode.textContent.toLowerCase() === 'no') {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'block';
            } else if (element.parentNode.textContent.toLowerCase() === "haven't joined") {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'none';
            }
        })
    });
}

CompensationForm.prototype.prepareStep4 = function () {
    var _this = this;
    var plans = Object.values(_this.planData.compensation_plans).reverse();
    var possibleValues = plans.map(function (x) { return parseInt(x.monthly_cost); });
    var min = possibleValues[0];
    var max = possibleValues[possibleValues.length - 1];
    var initialValue = this.monthlyCost;
    // This is for the slider on hire step 4.
    // Reminder: we mutate this range below. The most important thing to remember is that the range keys must be
    // percentages, with '%' at the end.
    var range = {
        min: min,
        max: max,
    };
    possibleValues.forEach(function(x, i) {
        var percent = Math.round(i / (possibleValues.length - 1) * 100);
        range[percent + '%'] = x;
    });

    this.planSelector = noUiSlider.create(document.getElementById('change-plan-slider'), {
        start: [initialValue],
        behaviour: 'tap-drag',
        range: range,
        format: wNumb({
            decimals: 0
        }),
        pips: {
            mode: 'values',
            values: possibleValues,
            density: 100,
            format: wNumb({
                prefix: '$',
                decimals: 0
            })
        },
        snap: true,
    });

    window.planSelector = this.planSelector;
    this.planSelector.on('change', function (values, handle) {
        var val = parseFloat(values[handle]);
        // Note 972498572:
        // I set the min on the range to be min (25 right now. Importantly, not 0) because I did not want a pip to be
        // drawn at 0. Therefore the min value we'll receive here is 25. But if the slider handle was dragged, it might
        // appear as 0. So we force it to the min either way to handle.
        if (val <= min) {
            window.planSelector.set(min);
        }
    });
    this.planSelector.on('update', function (values, handle) {
        if (parseFloat(values[handle]) < min) {
            return;
        }
        _this.monthlyCost = parseFloat(values[handle]);
        _this.updatePlan();
    })
};

CompensationForm.prototype.prepareStep5 = function () {
    var _this = this;
    var plan = this.form.querySelector('.plan-modal');
    var planLink = this.form.querySelector('.plan-link').querySelector('.plan-link');
    var planSubmit;
    var modal;

    new Cleave('#card-number', {
        creditCard: true,
    })

    new Cleave('#expiration-date', {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'y']
    })

    new Cleave('#phone-number', {
        phone: true,
        phoneRegionCode: 'us',
    })

    planLink.addEventListener('click', function (event) {
        event.preventDefault();
        var modalContent = _this.form.querySelector('.plan-modal').cloneNode(true).innerHTML;
        modal = bootbox.dialog({
            title: '',
            message: modalContent,
        }).off("shown.bs.modal").init(function() {
            $('.modal-dialog').addClass('ica-modal');
        });


        var planFields = Array.prototype.slice.call(document.querySelector('.ica-modal').querySelectorAll('input'));
        planSubmit = document.querySelector('.ica-modal').querySelector('#plan-submit');

        planSubmit.addEventListener('click', function (event) {
            var errors = [];
            event.preventDefault();
            planFields.forEach(function (element) {
                if (element.type !== 'submit') {
                    if (!element.value) {
                        element.parentNode.classList.add('form-error');
                        element.nextElementSibling.textContent = 'This field is required.';
                        errors.push(element);
                    } else {
                        element.parentNode.classList.remove('form-error');
                        element.nextElementSibling.textContent = '';
                    }
                }
            })

            if (!errors.length) {
                planFields.forEach(function (element) {
                    if (element.type !== 'submit') {
                        var value = element.value;
                        element = _this.form.querySelector('[name="' + element.name + '"]');
                        element.value = value;
                    }
                })

                _this.form.querySelector('[name="confirm"]').value = 1;
                planLink.classList.add('signed')
                planLink.textContent = 'Plan Signed';

                $('button.bootbox-close-button').trigger('click')
            }
        })
    })

    var paymentSelect = document.getElementById('initial-payment');
    if (!!paymentSelect) {
        $(paymentSelect).trigger('change');
    }

    if (w9.enabled && !w9.hasCompleted) {
        var $wrapper = $('#checkout-form');
        $wrapper.addClass('wusa-disabled');
        var $startButton = $('.w9-start-button');
        var $verifyLink = $('.w9-verify-link');
        var $verifyButton = $('.w9-verify-button');
        var $mustSignW9Message = $('.must-sign-w9-message');
        $startButton.on('click', function() {
            $startButton.hide();
            $verifyLink.hide();
            var iFrameNode = document.createElement('iframe');
            iFrameNode.src = w9.iFrameUrl;
            $('.w9-embedded-form').append(iFrameNode);
            $verifyButton.show();
        });
        function verify() {
            $('.w9-verify-response .verified').hide();
            $('.w9-verify-response .unverified').hide();
            $('.w9-verify-response .error').hide();
            $mustSignW9Message.hide();
            var $waitSpinners = $('.w9 .wait-spinner');
            var $buttonArrows = $('.w9 .button-arrow');
            $buttonArrows.hide();
            $waitSpinners.show();
            // Use inline-block, or fa-spin won't have an effect.
            $waitSpinners.css('display', 'inline-block');
            $waitSpinners.addClass('fa-spin');
            $.ajax({
                dataType: 'json',
                url: w9.apiUrl,
                method: 'POST',
                complete: function() {
                    $buttonArrows.show();
                    $waitSpinners.hide();
                    $waitSpinners.removeClass('fa-spin');
                },
                error: function(error) {
                    var $error = $('.w9-verify-response .error');
                    $error.show();
                    var message = error.message;
                    if (error.responseJSON && error.responseJSON.errors && error.responseJSON.errors.length) {
                        message = error.responseJSON.errors[0];
                    }
                    $error.text(message);
                },
                success: function(data) {
                    if (data.hasCompleted) {
                        $startButton.hide();
                        $verifyLink.hide();
                        $verifyButton.hide();
                        $('.w9-verify-response .verified').show();
                        $wrapper.removeClass('wusa-disabled');
                        w9.hasSubsequentlyCompleted = true;
                    } else {
                        $('.w9-verify-response .unverified').show();
                    }
                },
            });
        }
        $verifyButton.on('click', verify);
        $verifyLink.on('click', verify);
    }
}

CompensationForm.prototype.prepareStep6 = function () {
    var _this = this;
    var branchContainer = this.form.querySelector('.branch-entity-codes');
    var checkboxes = branchContainer.querySelectorAll('.checkbox');
    checkboxes = Array.prototype.slice.call(checkboxes);

    checkboxes.forEach(function (element) {
        element.addEventListener('click', _this.branchCheckbox.bind(_this));
    })

    if (this.form.querySelector('#branch').value) {
        var value = this.form.querySelector('#').value;
        var element = this.form.querySelector('[data-branch="' + value + '"]');
        var event = document.createEvent('Event');
        event.initEvent('click', true, true, {});
        element.dispatchEvent(event);
    }

    var branches = branchContainer.querySelectorAll('.branch-entity');
    for (var idx in branches) {
        var anchorPoint = branches[idx];
        if (anchorPoint instanceof HTMLElement) {
            var id = anchorPoint.dataset.branch;
            var tooltipContent = _this.form.querySelector('#' + id).innerHTML;
            var mapTrigger = anchorPoint.querySelector('.map')
            $(mapTrigger).tooltipster({
                trigger: 'click',
                content: tooltipContent.toNode(),
                contentAsHTML: true,
                interactive: true,
                theme: 'tooltipster-shadow',
            });
        }
    }

    var haveNotTransferredInputs = this.form.querySelectorAll('input[name="license_transfer"]');
    var azDreLink = this.form.querySelector('.az-dre-link');
    haveNotTransferredInputs.forEach(function(input) {
        input.addEventListener('change', function(event) {
            var shouldShow = event.target.value.startsWith('I have not');
            if (shouldShow) {
                azDreLink.classList.remove('hide');
            } else {
                azDreLink.classList.add('hide');
            }
        });
    })
}

CompensationForm.prototype.branchCheckbox = function (event) {
    var _this = this;
    var element = event.target;
    var checkboxes = this.form.querySelector('.branch-entity-codes').querySelectorAll('.checkbox');
    checkboxes = Array.prototype.slice.call(checkboxes);

    checkboxes.forEach(function (element) {
        element.classList.remove('checked');
    })

    element.classList.add('checked');
    this.form.querySelector('#branch').value = element.dataset.branch;
}

CompensationForm.prototype.updatePlan = function () {
    var _this = this;

    _this.selectedPlan = Object.values(_this.planData.compensation_plans).find(function(x) {
        return _this.monthlyCost === parseFloat(x.monthly_cost);
    });

    if (this.selectedPlan !== this.previousPlan) {
        this.previousPlan = this.selectedPlan;

        $(this.initialPaymentDropdown).select2('destroy');
        this.initialPaymentDropdown.options.length = 0;

        for (var idx in this.selectedPlan.initial_payments) {
            var option = _this.selectedPlan.initial_payments[idx];
            var selected = (parseFloat(option.payment_amount) === parseFloat(this.initialPayment));
            var optionObj = new Option(parseFloat(option.payment_amount).formatMoney(2), option.payment_amount, selected, selected);
            _this.initialPaymentDropdown.appendChild(optionObj);
        }

        $(this.initialPaymentDropdown).select2(_this.select2Options);

        if (this.step === 4) {
            Array.prototype.slice.call(this.planPrintouts).forEach(function (printout) {
                if (printout.dataset.plan.toLowerCase() === _this.selectedPlan.letter.toLowerCase()) {
                    printout.style.display = 'block';
                } else {
                    printout.style.display = 'none';
                }
            });
        }
    }

    if (this.step === 4) {
        var planDocumentNode = Array.prototype.find.call(_this.planPrintouts, function(planDocument) {
            return planDocument.dataset.plan === _this.selectedPlan.letter;
        });
        $('.view-details').attr('href', $(planDocumentNode).attr('href'));
    }

    this.updateFees();
};

CompensationForm.prototype.updateFees = function () {
    var _this = this;

    var monthlyFeeDiscounts = [1, 4, 5, 7, 8];
    if (this.planData.coupon_code && Object.keys(_this.planData.coupon_code).length) {
        if (monthlyFeeDiscounts.indexOf(parseInt(_this.planData.coupon_code.discount_type)) > -1) {
            this.monthlyFee = (parseInt(this.monthlyCost) * 0.01 * (100 - _this.planData.coupon_code.percentage_discount));
        } else {
            this.monthlyFee = this.monthlyCost;
        }
    } else {
        this.monthlyFee = this.monthlyCost;
    }

    this.form.querySelector('.monthly-fee').textContent = this.monthlyFee.formatMoney(2);
    this.form.querySelector('#monthly-fee').value = this.monthlyFee;
    this.form.querySelector('#monthly-cost').value = this.monthlyCost;
    this.form.querySelector('#annual-fee').value = this.selectedPlan.annual_fee.formatMoney(0);

    this.form.querySelector('.setup-fee').textContent = parseFloat(this.selectedPlan.setup_fee).formatMoney(2);
    this.form.querySelector('#setup-fee').value = this.selectedPlan.setup_fee;

    this.form.querySelector('.annual-fee').textContent = parseFloat(this.selectedPlan.annual_fee).formatMoney(2);
    this.form.querySelector('#annual-fee').value = this.selectedPlan.annual_fee;

    if (this.step === 4) {
        this.form.querySelector('.selected-plan-letter').textContent = this.selectedPlan.plan_name;
        this.form.querySelector('.current-monthly-cost').querySelector('h2').textContent = this.monthlyCost.formatMoney(0);
        this.form.querySelector('.annual-fee').textContent = this.selectedPlan.annual_fee.formatMoney(2);
    }

    if (this.step === 4 || this.step === 5) {
        this.form.querySelector('#selected-payment').value = this.initialPayment;
    }

    this.total = this.calculateTotal();

    if (this.total && this.braintreeForm) {
        this.braintreeForm.showForm();
    } else if (this.braintreeForm && !this.total) {
        this.braintreeForm.disable();
    }

    this.form.querySelector('#total').value = this.total;
    this.form.querySelector('#total-due').textContent = this.total.formatMoney(2);

    this.updateCallouts();
}

CompensationForm.prototype.updateCallouts = function () {
    var _this = this;

    var yearlyCapStr = this.selectedPlan.yearly_cap
        ? VuriaUtil.displayAsDollars(this.selectedPlan.yearly_cap)
        : 'None';

    document.querySelector('.transaction-fee').querySelector('h1').textContent = this.planData.transaction_fee.formatMoney(0);
    document.querySelector('.monthly-cost').querySelector('h1').textContent = this.monthlyCost.formatMoney(0);
    document.querySelector('.yearly-cap').querySelector('h1').textContent = yearlyCapStr;

    var initialPaymentMessage = this.form.querySelector('#initial-payment-message');
    if (this.initialPayment < 200) {
        initialPaymentMessage.style.display = 'block';
    } else {
        initialPaymentMessage.style.display = 'none';
    }
}

CompensationForm.prototype.calculateTotal = function () {
    var monthly = parseFloat(this.monthlyFee);
    var initial = parseFloat(this.initialPaymentDropdown.value);
    var setupFee = parseFloat(this.selectedPlan.setup_fee);
    var annualFee = parseFloat(this.selectedPlan.annual_fee);

    return monthly + initial + setupFee + annualFee;
}



if (VuriaUtil.elementExists('#compensation-form')) {
    window.test = new CompensationForm();
}
